import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export interface BookmarkletProps {
  id: string
}
export const Bookmarklet: React.SFC<BookmarkletProps> = ({ id, children }) => {
  const style: React.CSSProperties = {
    margin: '0 auto',
    padding: '10px',
    display: 'block',
    width: '180px', // Needs to be calculated based on the size of the text bleh
    textAlign: 'center',
    background: '#403233',
    color: 'white',
    borderRadius: '5px',
    textDecoration: 'none',
  }

  const data = useStaticQuery(graphql`
    query BookmarkletQuery {
      allEmbed {
        nodes {
          id
          content
        }
      }
    }
  `)
  console.log(data)
  const embed = data.allEmbed.nodes.find((n) => n.id === id)
  if (!embed) {
    return <p>Couldn't find any embeds with name: {id}</p>
  }
  return (
    <a
      style={style}
      ref={(node) =>
        node?.setAttribute(
          'href',
          `javascript:(function(){${embed.content}})()`,
        )
      }
    >
      {children}
    </a>
  )
}
