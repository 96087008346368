
import * as React from 'react'

export const Button: React.FC<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>
    = (props) => {
        const style: React.CSSProperties = {
            margin: '0 auto',
            padding: '10px',
            display: 'block',
            width: '180px', // Needs to be calculated based on the size of the text bleh
            textAlign: 'center',
            background: '#403233',
            color: 'white',
            borderRadius: '5px',
            textDecoration: 'none',
        }

        return (
            <a
                style={style}
                {...props}
            />
        )
    }
