import * as React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'

import 'typeface-patua-one'

import './global.scss'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Bookmarklet } from './Bookmarklet'
import { Button } from './Button'
import { CodeEmbed } from './CodeEmbed'

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: any
}

export const Layout: React.SFC<DefaultLayoutProps> = ({ children }) => (
  <div className="content">
    <Helmet
      title="Gustav Bylund"
      meta={[
        {
          name: 'description',
          content: 'Gustav is a web and app developer from Sweden',
        },
        {
          name: 'keywords',
          content:
            'konsult, webbutvecklare, utvecklare, systemutvecklare, webbsida, app, norrköping, linköping, östergötland',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <Header />
    <main>
      <MDXProvider
        components={{
          Bookmarklet,
          CodeEmbed,
          Button,
        }}
      >
        {children}
      </MDXProvider>
    </main>
    <Footer />
  </div>
)

export default Layout
