import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import * as styles from './header.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import { HeaderQueryQuery } from '../../graphql-types'

export const Header = () => {
  const data = useStaticQuery<HeaderQueryQuery>(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      avatar: file(absolutePath: { regex: "/avatar.(jpeg|jpg|gif|png)/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 150, height: 150)
        }
      }
    }
  `)
  console.log(data)

  return (
    <header className={styles.header}>
      <GatsbyImage className={styles.logo} image={data.avatar.childImageSharp.gatsbyImageData} alt="Profile picture" />
      <Link className={styles.name} to="/">
        <h1>
          <span>Gustav</span>
          <span>Bylund</span>
        </h1>
      </Link>
      <address>
        <a href="tel:+46730262686">+46 (0)73 026 26 86</a>
        <a href="mailto:hello@gustavbylund.se">hello@gustavbylund.se</a>
        <a href="https://github.com/maistho" className={styles.github} rel="noopener" target="_blank">
          <FontAwesomeIcon icon={faGithub} size="lg" color="black" height={16} />
          <span>maistho</span>
        </a>
      </address>
      <nav>
        <Link activeClassName="active" to="/">
          About
        </Link>
        <Link activeClassName="active" to="/blog">
          Blog
        </Link>
      </nav>
    </header>
  )
}
