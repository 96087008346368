import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { CodeEmbedQueryQuery } from '../../graphql-types'

export const CodeEmbed = ({ id, prefix, children }: any) => {
  const style: React.CSSProperties = {
    margin: '0 auto',
    padding: '10px',
    display: 'block',
    width: '180px', // Needs to be calculated based on the size of the text bleh
    textAlign: 'center',
    background: '#403233',
    color: 'white',
    borderRadius: '5px',
    textDecoration: 'none',
  }

  const data: CodeEmbedQueryQuery = useStaticQuery(graphql`
    query CodeEmbedQuery {
      allEmbed {
        nodes {
          id
          extension
          highlighted
        }
      }
    }
  `)
  console.log(data)
  const embed = data.allEmbed.nodes.find(n => n.id === id)
  if (!embed) {
    return <p>Couldn't find any embeds with name: {id}</p>
  }
  return (
    <div className="gatsby-highlight">
      <pre className={`language-${embed.extension}`}>
        <code
          dangerouslySetInnerHTML={{
            __html: `${prefix ? prefix : ''}${embed.highlighted}`,
          }}
        />
      </pre>
    </div>
  )
}
